.root {
  font-weight: var(--font-weight-bold);
  display: inline-flex;
  align-items: center;
  border-radius: var(--sw-border-radius);
}

.icon {
  font-size: 0;
}

// Modifiers
.root {
  // Size
  &.sizeSmall {
    font-size: var(--font-size-12px, 12px);
    line-height: normal;
    padding: 3px 7px;

    .icon {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      margin-left: 0px;
    }
  }

  &.sizeStandard {
    font-size: var(--font-size-16px, 16px);
    line-height: normal;
    padding: 10px 14px;

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }

  // Variant
  &.variantPrimary {
    background-color: var(--primary-color);
    color: var(--primary-background-text-color);

    .iconSvgIcon {
      fill: var(--primary-background-text-color);
    }
  }

  &.variantSecondary {
    background-color: var(--secondary-color);
    color: var(--secondary-background-text-color);

    .iconSvgIcon {
      fill: var(--secondary-background-text-color);
    }
  }

  &.variantSuccess {
    background-color: var(--success-color);
    color: var(--success-background-text-color);

    .iconSvgIcon {
      fill: var(--success-background-text-color);
    }
  }

  &.variantDanger {
    background-color: var(--danger-color);
    color: var(--danger-background-text-color);

    .iconSvgIcon {
      fill: var(--danger-background-text-color);
    }
  }

  &.variantWarning {
    background-color: var(--warning-color);
    color: var(--warning-background-text-color);

    .iconSvgIcon {
      fill: var(--warning-background-text-color);
    }
  }

  &.variantInfo {
    background-color: var(--info-color);
    color: var(--info-background-text-color);

    .iconSvgIcon {
      fill: var(--info-background-text-color);
    }
  }

  &.variantNeutral {
    background-color: var(--neutral-color);
    color: var(--neutral-background-text-color);

    .iconSvgIcon {
      fill: var(--neutral-background-text-color);
    }
  }
}
